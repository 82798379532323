import { TFunction } from 'i18next'

import { ITravelPurposeEnum } from '../../../Lib/graphql'
import { IGpsOptions, ITravelPurposeOptions } from './interfaces'

export const gpsOptions = (translate: TFunction): Array<IGpsOptions> => [
  {
    label: translate('Business'),
    value: ITravelPurposeEnum.BUSINESS,
  },
  {
    label: translate('Private'),
    value: ITravelPurposeEnum.PERSONAL,
  },
]

export const travelPurposeOptions = (translate: TFunction): Array<ITravelPurposeOptions> => [
  {
    label: translate('Business'),
    value: ITravelPurposeEnum.BUSINESS,
  },
  {
    label: translate('Private'),
    value: ITravelPurposeEnum.PERSONAL,
  },
  {
    label: translate('Commute'),
    value: ITravelPurposeEnum.COMMUTE,
  },
]

export const gpsOptionForValue = (value: ITravelPurposeEnum, translate: TFunction): IGpsOptions => {
  return gpsOptions(translate).find((option) => option.value === value) || gpsOptions(translate)[1]
}

export const travelPurposeOptionForValue = (value: ITravelPurposeEnum, translate: TFunction): ITravelPurposeOptions => {
  return travelPurposeOptions(translate).find((option) => option.value === value) || travelPurposeOptions(translate)[1]
}
