import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { TLocale } from '../../Lib/sharedInterfaces'
import { THook } from './interfaces'

export const useTranslationDecorator = (): THook => {
  const translation = useTranslation()

  const locale = useMemo((): TLocale => {
    switch (translation.i18n.language) {
      case 'nl':
        return 'nl'
      case 'en':
        return 'en'
      default:
        return 'en'
    }
  }, [translation.i18n.language])

  return useMemo(
    () => ({
      ...translation,
      locale,
    }),
    [locale, translation]
  )
}
