import { TFunction } from 'i18next'

import { ICountryIsoCodeEnum, ITravelPurposeEnum } from '../../Lib/graphql'
import { ICountryOptions, IGpsOptions } from './interfaces'

export const countryOptions = (translate: TFunction): Array<ICountryOptions> => [
  {
    label: translate('The Netherlands'),
    value: ICountryIsoCodeEnum.NL,
  },
  {
    label: translate('Belgium'),
    value: ICountryIsoCodeEnum.BE,
  },
  {
    label: translate('Germany'),
    value: ICountryIsoCodeEnum.DE,
  },
  {
    label: translate('Luxembourg'),
    value: ICountryIsoCodeEnum.LU,
  },
  {
    label: translate('Austria'),
    value: ICountryIsoCodeEnum.AT,
  },
  {
    label: translate('Spain'),
    value: ICountryIsoCodeEnum.ES,
  },
  {
    label: translate('France'),
    value: ICountryIsoCodeEnum.FR,
  },
]

export const gpsOptions = (translate: TFunction): Array<IGpsOptions> => [
  {
    label: translate('For business'),
    value: ITravelPurposeEnum.BUSINESS,
  },
  {
    label: translate('Private'),
    value: ITravelPurposeEnum.PERSONAL,
  },
]

export const countryOptionForValue = (
  value: ICountryIsoCodeEnum,
  translate: TFunction
): ICountryOptions | undefined => {
  return countryOptions(translate).find((option) => option.value === value)
}

export const dateStringToDate = (dateString: string): Date => {
  return new Date(Date.parse(dateString))
}

export const gpsOptionForValue = (value: ITravelPurposeEnum, translate: TFunction): IGpsOptions => {
  return gpsOptions(translate).find((option) => option.value === value) || gpsOptions(translate)[1]
}
