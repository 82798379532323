/* eslint-disable sonarjs/cognitive-complexity */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { SelectValue } from '../../Components/FormComponents/Select/interfaces'
import { ICountryIsoCodeEnum, ITravelPurposeEnum, useGetUserInformationQuery } from '../../Lib/graphql'
import { isImposter } from '../../Lib/jwtHelper'
import { countryOptions } from '../../Pages/Profile/helper'
import {
  gpsOptionForValue,
  gpsOptions,
  travelPurposeOptionForValue,
  travelPurposeOptions,
} from '../../Pages/Profile/ProfileSettings/helper'
import { ICountryOptions, IGpsOptions, ITravelPurposeOptions } from '../../Pages/Profile/ProfileSettings/interfaces'
import { ICurrentUserContext, TVisibility } from './interfaces'

export const CurrentUserContext = React.createContext<ICurrentUserContext>({
  email: '',
  setEmail: (_: string) => {},
  phoneNumber: '',
  setPhoneNumber: (_: string) => {},
  firstName: '',
  setFirstName: (_: string) => {},
  fullName: () => '',
  infix: '',
  setInfix: (_: string) => {},
  initials: '',
  setInitials: (_: string) => {},
  lastName: '',
  setLastName: (_: string) => {},
  becameEmployee: false,
  optedInForBetaInterface: false,
  dateOfBirth: null,
  setDateOfBirth: (_: Date) => {},
  street: '',
  setStreet: (_: string) => {},
  streetNumber: '',
  setStreetNumber: (_: string) => {},
  streetNumberSuffix: '',
  setStreetNumberSuffix: (_: string) => {},
  city: '',
  setCity: (_: string) => {},
  zipcode: '',
  setZipcode: (_: string) => {},
  countryIsoCode: { label: '', value: ICountryIsoCodeEnum.NL },
  setCountryIsoCode: (_: { label: string; value: ICountryIsoCodeEnum }) => {},
  gpsSettings: { label: '', value: ITravelPurposeEnum.PERSONAL },
  setGpsSettings: (_: { label: string; value: ITravelPurposeEnum }) => {},
  travelPurposeDuringWeek: { label: '', value: ITravelPurposeEnum.PERSONAL },
  setTravelPurposeDuringWeek: (_: { label: string; value: ITravelPurposeEnum }) => {},
  travelPurposeDuringWeekend: { label: '', value: ITravelPurposeEnum.PERSONAL },
  setTravelPurposeDuringWeekend: (_: { label: string; value: ITravelPurposeEnum }) => {},
  visibility: { fixLocation: false, releaseNotes: false, updatedTermsAndCondition: false },
  setVisibility: () => {},
})

export const CurrentUserProvider: React.FC<{ children: React.ReactNode }> = (props): JSX.Element => {
  const { t } = useTranslation()
  const [visibility, setVisibility] = useState<TVisibility>({
    releaseNotes: false,
    fixLocation: false,
    updatedTermsAndCondition: false,
  })

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [infix, setInfix] = useState<string>('')
  const [initials, setInitials] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [becameEmployee, setBecameEmployee] = useState<boolean>(false)
  const [optedInForBetaInterface, SetOptedInForBetaInterface] = useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [employeeNumber, setEmployeeNumber] = useState<string>('')
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null)
  const [city, setCity] = useState<string>('')
  const [street, setStreet] = useState<string>('')
  const [streetNumber, setStreetNumber] = useState<string>('')
  const [streetNumberSuffix, setStreetNumberSuffix] = useState<string>('')
  const [zipcode, setZipcode] = useState<string>('')
  const [countryIsoCode, setCountryIsoCode] = useState<SelectValue<ICountryOptions>>(countryOptions(t)[0])
  const [workCity, setWorkCity] = useState<string>('')
  const [workStreet, setWorkStreet] = useState<string>('')
  const [workStreetNumber, setWorkStreetNumber] = useState<string>('')
  const [workStreetNumberSuffix, setWorkStreetNumberSuffix] = useState<string>('')
  const [workZipcode, setWorkZipcode] = useState<string>('')
  const [workCountryIsoCode, setWorkCountryIsoCode] = useState<SelectValue<ICountryOptions>>(countryOptions(t)[0])
  const [gpsSettings, setGpsSettings] = useState<IGpsOptions>(gpsOptions(t)[1])
  const [travelPurposeDuringWeek, setTravelPurposeDuringWeek] = useState<ITravelPurposeOptions>(
    travelPurposeOptions(t)[1]
  )
  const [travelPurposeDuringWeekend, setTravelPurposeDuringWeekend] = useState<ITravelPurposeOptions>(
    travelPurposeOptions(t)[1]
  )

  const { data, loading } = useGetUserInformationQuery()
  const { children } = props

  useEffect(() => {
    if (loading) return
    setFirstName(data?.me?.profile.firstName || '')
    setLastName(data?.me?.profile.lastName || '')
    setEmail(data?.me?.profile.email || '')
    setPhoneNumber(data?.me?.profile.phoneNumber || '')
    setInfix(data?.me?.profile.infix || '')
    setInitials(data?.me?.profile.initials || '')
    setBecameEmployee(isImposter())
    setInitialized(true)
    SetOptedInForBetaInterface(data?.me?.profile.usesNewInterface || false)
    setEmployeeNumber(data?.me?.profile.employeeNumber || '')
    const incomingDateOfBirth = data?.me?.profile.dateOfBirth
    setDateOfBirth(incomingDateOfBirth ? new Date(incomingDateOfBirth) : null)
    setCity(data?.me?.profile.homeAddress?.city || '')
    setStreet(data?.me?.profile.homeAddress?.street || '')
    setStreetNumber(data?.me?.profile.homeAddress?.streetNumber || '')
    setStreetNumberSuffix(data?.me?.profile.homeAddress?.streetNumberSuffix || '')
    setZipcode(data?.me?.profile.homeAddress?.zipcode || '')
    setCountryIsoCode(
      {
        label: data?.me?.profile.homeAddress?.employeeCountryIsoCode?.humanized,
        value: data?.me?.profile.homeAddress?.employeeCountryIsoCode?.value,
      } || { humanized: '', value: '' }
    )
    setWorkCity(data?.me?.profile.workAddress?.city || '')
    setWorkStreet(data?.me?.profile.workAddress?.street || '')
    setWorkStreetNumber(data?.me?.profile.workAddress?.streetNumber || '')
    setWorkStreetNumberSuffix(data?.me?.profile.workAddress?.streetNumberSuffix || '')
    setWorkZipcode(data?.me?.profile.workAddress?.zipcode || '')
    setWorkCountryIsoCode(
      {
        label: data?.me?.profile.workAddress?.employeeCountryIsoCode?.humanized,
        value: data?.me?.profile.workAddress?.employeeCountryIsoCode?.value,
      } || ''
    )
    setGpsSettings(gpsOptionForValue(data?.me?.profile?.preferredTravelPurpose as ITravelPurposeEnum, t))
    setTravelPurposeDuringWeek(
      travelPurposeOptionForValue(
        data?.me?.profile?.defaultMobilityCardTransactionTravelPurpose as ITravelPurposeEnum,
        t
      )
    )
    setTravelPurposeDuringWeekend(
      travelPurposeOptionForValue(
        data?.me?.profile?.defaultMobilityCardWeekendTransactionTravelPurpose as ITravelPurposeEnum,
        t
      )
    )
  }, [data, loading, t])

  const fullName = useCallback(() => {
    return [firstName, infix, lastName].filter((part) => part !== '').join(' ')
  }, [firstName, infix, lastName])

  const options = useMemo(() => {
    return {
      visibility,
      setVisibility,
      email,
      setEmail,
      phoneNumber,
      setPhoneNumber,
      firstName,
      setFirstName,
      fullName,
      infix,
      setInfix,
      initials,
      setInitials,
      lastName,
      setLastName,
      dateOfBirth,
      setDateOfBirth,
      becameEmployee,
      optedInForBetaInterface,
      city,
      setCity,
      street,
      setStreet,
      streetNumber,
      setStreetNumber,
      streetNumberSuffix,
      setStreetNumberSuffix,
      zipcode,
      setZipcode,
      countryIsoCode,
      setCountryIsoCode,
      workCity,
      setWorkCity,
      workStreet,
      setWorkStreet,
      workStreetNumber,
      setWorkStreetNumber,
      workStreetNumberSuffix,
      setWorkStreetNumberSuffix,
      workZipcode,
      setWorkZipcode,
      workCountryIsoCode,
      setWorkCountryIsoCode,
      employeeNumber,
      gpsSettings,
      setGpsSettings,
      travelPurposeDuringWeek,
      setTravelPurposeDuringWeek,
      travelPurposeDuringWeekend,
      setTravelPurposeDuringWeekend,
    }
  }, [
    visibility,
    email,
    phoneNumber,
    firstName,
    fullName,
    infix,
    initials,
    lastName,
    dateOfBirth,
    becameEmployee,
    optedInForBetaInterface,
    city,
    street,
    streetNumber,
    streetNumberSuffix,
    zipcode,
    countryIsoCode,
    workCity,
    workStreet,
    workStreetNumber,
    workStreetNumberSuffix,
    workZipcode,
    workCountryIsoCode,
    employeeNumber,
    gpsSettings,
    travelPurposeDuringWeek,
    travelPurposeDuringWeekend,
  ])

  if (!initialized) return <Skeleton />

  return <CurrentUserContext.Provider value={options}>{children}</CurrentUserContext.Provider>
}
